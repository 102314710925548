const runningPromMap = {}

export function syncronized(fn) {
  const symbol = Symbol()

  return async function () {
    const prom = runningPromMap[symbol]
    if (prom) {
      await prom
    }

    const currProm = fn.apply(this,arguments)
    runningPromMap[symbol] = currProm
    return currProm
  }

}
