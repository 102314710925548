import {v2Request} from "./v2Request";


export const planPayV2Api = {

    async findMy({current, size}) {
        return v2Request.get(`/planPay/findMy`, {params: {current, size}});
    }

};
